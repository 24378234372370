import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/components/layout/navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/components/layout/search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage"] */ "/opt/buildhome/repo/src/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuItem","DropdownMenuSeparator"] */ "/opt/buildhome/repo/src/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetTrigger","SheetContent","SheetClose"] */ "/opt/buildhome/repo/src/components/ui/sheet.tsx");
